import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Userfront from '@userfront/toolkit/react';
import Sidebar from './../../components/Sidebar';
import NavBar from '../../components/Navbar';
import './Onboarding.css';

import api from './../../api/axios'; // Import your axios instance
import OnboardingWidget from '../../components/OnboardingWidget';

import { usePersistentCollapsedState } from './../../components/SidebarContext';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import i18n from './../../i18n'; // Import your i18n configuration

const Onboarding = () => {

  const { t } = useTranslation(); // Initialize translation

  const currentUser = Userfront.user.email;

  const collapsed = usePersistentCollapsedState();

  // Fetch user language preference and set it
  useEffect(() => {
    const fetchUserLanguage = async () => {
      try {
        const response = await api.get(`/user?email=${currentUser}`);
        const userLanguage = response.data.language; // Assume this returns the language setting
        i18n.changeLanguage(userLanguage); // Change language using i18n
      } catch (error) {
        console.error('Error fetching user language settings:', error);
      }
    };
    
    fetchUserLanguage();
  }, [currentUser]); 


  return (
    <div className={`dashboard ${collapsed ? 'collapsed' : ''}`}>
        <Sidebar />
        <div className="container">
            <NavBar />
            <div className="row page-row">
                <div className={`col-md-12 col-sm-12 onboarding`}>
                    <div className="activities-widget activities-widget-home row-1">  
                        <OnboardingWidget />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
  
  

};

export default Onboarding;
