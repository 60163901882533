import React, { useState, useEffect } from 'react';
import './CampaignDetail.css';
import Sidebar from '../../components/Sidebar';
import NavBar from '../../components/Navbar';
import axios from 'axios';
import Userfront from "@userfront/toolkit/react";
import { useParams } from 'react-router-dom';
import { usePersistentCollapsedState } from './../../components/SidebarContext';
import SaveRestore from '../../components/TemplateBuilder';
import api from './../../api/axios';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const CampaignDetail = () => {
  const { id } = useParams();
  const [selectedLeadListId, setSelectedLeadListId] = useState('');
  const [leadLists, setLeadLists] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [campaign, setCampaign] = useState({});
  const collapsed = usePersistentCollapsedState();
  const [statusMessage, setStatusMessage] = useState(null);

  const { t } = useTranslation();

  const fetchLeadLists = async () => {
    try {
      const user = await Userfront.user.email;
      const response = await api.get(`/lead-lists?user=${user}`);
      setLeadLists(response.data);
    } catch (error) {
      console.error('Error fetching lead lists:', error.response?.data || error.message);
    }
  };

  const fetchCampaign = async () => {
    try {
      const response = await api.get(`/campaigns/${id}`);
      setCampaign(response.data);
      if (response.data.leadList) {
        setSelectedLeadListId(response.data.leadList);
      } else {
        setSelectedLeadListId('');
      }
    } catch (error) {
      console.error('Error fetching campaign:', error.response?.data || error.message);
    }
  };

  useEffect(() => {
    fetchLeadLists();
    fetchCampaign();
  }, [id]);

  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStepTitle = (step) => {
    switch (step) {
      case 1:
        return t('campaign.step1');
      case 2:
        return t('campaign.step2');
      case 3:
        return t('campaign.step3');
      case 4:
        return t('campaign.step4');
      default:
        return '';
    }
  };

  const getProgressBarWidth = (step) => {
    const stepWidth = 25;
    return step <= currentStep ? `${stepWidth}%` : '0%';
  };

  const handleLeadListChange = (e) => {
    const updatedValue = e.target.value;
    setSelectedLeadListId(updatedValue);
  };

  const updateCampaignWithLeadList = async () => {
    try {
      await api.post(`/campaigns/${id}`, {
        leadListId: selectedLeadListId,
      });
    } catch (error) {
      console.error('Error updating campaign:', error.response.data);
    }
  };

  const saveConfiguredWorkflow = async (configuredWorkflow) => {
    try {
      await api.post(`/campaigns/${id}/configure-workflow`, {
        configuredWorkflow,
      });
    } catch (error) {
      console.error('Error saving configured workflow:', error.response.data);
    }
  };

  const handleActivateCampaign = async () => {
    try {
      const response = await api.post(`/campaigns/activate/${id}`);
      setStatusMessage(response.data.message);
      fetchCampaign();
    } catch (error) {
      console.error('Error activating campaign:', error.response.data);
      setStatusMessage(t('campaign.activateFailed'));
    }
  };

  const handlePauseCampaign = async () => {
    try {
      const response = await api.post(`/campaigns/pause/${id}`);
      setStatusMessage(response.data.message);
      fetchCampaign();
    } catch (error) {
      console.error('Error pausing campaign:', error.response.data);
      setStatusMessage(t('campaign.pauseFailed'));
    }
  };

  const handleResumeCampaign = async () => {
    try {
      const response = await api.post(`/campaigns/resume/${id}`);
      setStatusMessage(response.data.message);
      fetchCampaign();
    } catch (error) {
      console.error('Error resuming campaign:', error.response.data);
      setStatusMessage(t('campaign.resumeFailed'));
    }
  };

  const renderActionButton = () => {
    switch (campaign.status) {
      case 'Concept':
        return <button onClick={handleActivateCampaign} className="btn btn-primary">{t('campaign.activate')}</button>;
      case 'Active':
        return <button onClick={handlePauseCampaign} className="btn btn-warning">{t('campaign.pause')}</button>;
      case 'Paused':
        return <button onClick={handleResumeCampaign} className="btn btn-success">{t('campaign.resume')}</button>;
      default:
        return null;
    }
  };

  return (
    <div className={`dashboard ${collapsed ? 'collapsed' : ''}`}>
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row page-row">
          <div className="campaigns col-md-12">
            <div className='activities-widget'>
              <div className="campaign-detail">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                  <h2>{t('campaign.configurationTitle')}</h2>
                  <div className="button-container" style={{ display: 'flex', gap: '10px' }}>
                    <button onClick={prevStep} disabled={currentStep === 1} className="btn btn-secondary">
                      <FontAwesomeIcon icon={faArrowLeft} /> {t('campaign.previous')}
                    </button>
                    <button onClick={nextStep} disabled={currentStep === 4} className="btn btn-primary">
                      {t('campaign.next')} <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  </div>
                </div>

                <div className="progress">
                  <div className="progress-bar progress-bar-striped bg-info" style={{ width: getProgressBarWidth(1) }}>
                    {currentStep === 1 && t('campaign.selectLeads')}
                  </div>
                  <div className="progress-bar progress-bar-striped bg-info" style={{ width: getProgressBarWidth(2) }}>
                    {currentStep === 2 && t('campaign.configureWorkflow')}
                  </div>
                  <div className="progress-bar progress-bar-striped bg-info" style={{ width: getProgressBarWidth(3) }}>
                    {currentStep === 3 && t('campaign.campaignSettings')}
                  </div>
                  <div className="progress-bar progress-bar-striped bg-info" style={{ width: getProgressBarWidth(4) }}>
                    {currentStep === 4 && t('campaign.activateCampaign')}
                  </div>
                </div>

                <div className="steps">
                  <div className={`step ${currentStep === 1 ? 'active' : ''}`}>
                    {currentStep === 1 && (
                      <div>
                        <h2>{t('campaign.step1Title')}</h2>
                        <div className="filter-controls">
                          <select className="form-control" value={campaign.leadListId} onChange={handleLeadListChange} disabled={campaign.status !== "Concept"}>
                            <option value="">{t('campaign.selectLeadList')}</option>
                            {leadLists.map((leadList) => (
                              <option key={leadList._id} value={leadList._id}>
                                {leadList.leadListTitle}
                              </option>
                            ))}
                          </select>
                        </div>
                        {campaign.status === "Concept" && (
                          <button className="btn btn-primary saveLeadListCampaignButton" onClick={updateCampaignWithLeadList}>
                            {t('campaign.saveLeadList')}
                          </button>
                        )}

                      </div>
                    )}
                  </div>
                  <div className={`step ${currentStep === 2 ? 'active' : ''}`}>
                    {currentStep === 2 && (
                      <div>
                        <h2>{t('campaign.step2Title')}</h2>
                        <SaveRestore id={id} saveWorkflow={saveConfiguredWorkflow} campaignStatus={campaign.status} />
                      </div>
                    )}
                  </div>
                  <div className={`step ${currentStep === 3 ? 'active' : ''}`}>
                    {currentStep === 3 && (
                      <div>
                        <h2>{t('campaign.step3Title')}</h2>
                      </div>
                    )}
                  </div>
                  <div className={`step ${currentStep === 4 ? 'active' : ''}`}>
                    {currentStep === 4 && (
                      <div>
                        <h2>{t('campaign.step4Title')}</h2>
                        {renderActionButton()}
                        {statusMessage && <p>{statusMessage}</p>}

                        {campaign.status === "Completed" && (
                          <div>

                            <h3>Campaign results</h3>
                            <p>Status: {campaign.status}</p>
                            
                          </div>
                        )}

                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignDetail;
