import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Userfront from '@userfront/toolkit/react';

import Home from './pages/Home/Home';
import Settings from './pages/Settings/Settings';
import Campaigns from './pages/Campaigns/Campaigns';
import Messages from './pages/Messages/Messages';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Leads from './pages/Leads/Leads';
import LeadDetail from './pages/LeadDetail/LeadDetail';
import CampaignDetail from './pages/CampaignDetail/CampaignDetail';
import Team from './pages/Team/Team';
import SubscriptionComponent from './pages/Subscription/Subscription';
import ConfirmationPage from './pages/ConfirmationPage/ConfirmationPage';
import Onboarding from './pages/Onboarding/Onboarding';

function RequireAuth({ children }) {
  let location = useLocation();

  if (!Userfront.tokens.accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!Userfront.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<RequireAuth><Home /></RequireAuth>} />
      <Route path="/dashboard" element={<RequireAuth><Home /></RequireAuth>} />
      <Route path="/onboarding" element={<RequireAuth><Onboarding /></RequireAuth>} />
      <Route path="/settings" element={<RequireAuth><Settings /></RequireAuth>} />
      <Route path="/campaigns" element={<RequireAuth><Campaigns /></RequireAuth>} />
      <Route path="/campaigns/:id" element={<RequireAuth><CampaignDetail /></RequireAuth>} />
      <Route path="/messages" element={<RequireAuth><Messages /></RequireAuth>} />
      <Route path="/leads" element={<RequireAuth><Leads /></RequireAuth>} />
      <Route path="/leads/:id" element={<RequireAuth><LeadDetail /></RequireAuth>} />
      <Route path="/team" element={<RequireAuth><Team /></RequireAuth>} />
      <Route path="/subscription" element={<RequireAuth><SubscriptionComponent /></RequireAuth>} />
      <Route path="/confirmation" element={<ConfirmationPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Signup />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Routes>
  );
}

export default AppRouter;
