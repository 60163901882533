import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Userfront from '@userfront/toolkit/react';
import Sidebar from './../../components/Sidebar';
import NavBar from '../../components/Navbar';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faComment, faDollarSign, faEye, faPaperPlane, faBullseye, faUsers, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { usePersistentCollapsedState } from './../../components/SidebarContext';
import MixedChart from '../../components/LogChart';
import LimitsChart from '../../components/LimitsChart';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import api from './../../api/axios'; // Import your axios instance
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next'; // Import useTranslation
import i18n from './../../i18n'; // Import your i18n configuration


const Home = () => {

  const { t } = useTranslation(); // Initialize translation

  const [logs, setLogs] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [activeCampaigns, setActiveCampaigns] = useState([]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0); // New state for unread messages count
  const [isDataLoaded, setIsDataLoaded] = useState(false); // State to check if data is loaded
  const currentUser = Userfront.user.email;
  const [tooltips, setTooltips] = useState({});
  const [totalLeadsCount, setTotalLeadsCount] = useState(0);
  const [totalNewLeadsCount, setTotalNewLeadsCount] = useState(0);
  const [newConnections, setNewConnections] = useState(0); // New connections state
  const [newConnectionsThisWeek, setNewConnectionsThisWeek] = useState(0); // New connections this week
  const [percentageConnectionsChange, setPercentageConnectionsChange] = useState(0);
  
  const [activeCampaignsCount, setActiveCampaignsCount] = useState(0);
  const [previousLeadsCount, setPreviousLeadsCount] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [percentageChangeClass, setPercentageChangeClass] = useState(''); // State to hold the class for percentage change



  /** 

  useEffect(() => {
    // Check if the user is authenticated and email is confirmed
    if (!Userfront.user.password) {
       navigate("/reset-password");
    }

    console.log("User has password!");

  }, [navigate]);
*/

  // Fetch user language preference and set it
  useEffect(() => {
    const fetchUserLanguage = async () => {
      try {
        const response = await api.get(`/user?email=${currentUser}`);
        const userLanguage = response.data.language; // Assume this returns the language setting
        i18n.changeLanguage(userLanguage); // Change language using i18n
      } catch (error) {
        console.error('Error fetching user language settings:', error);
      }
    };
    
    fetchUserLanguage();
  }, [currentUser]); 

  useEffect(() => {
    fetchData();
    fetchUnreadMessagesCount(); // Fetch unread messages count on component mount
    fetchActiveCampaigns();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated and email is confirmed
    if (!Userfront.user.isConfirmed) {
      // navigate("/confirmation");
    }
  }, [navigate]);

  const fetchData = async () => {
    try {
      // Fetch user details to get organization ID
      const userResponse = await api.get(`/user?email=${currentUser}`);
  
      if (userResponse.status === 200 && userResponse.data.user) {
        const organizationId = userResponse.data.user.organizationId; // Assuming organizationId is part of user data
  
        // Fetch current total leads
        const leadsResponse = await api.get('/leads', { params: { user: currentUser } });
        const totalLeadsCount = leadsResponse.data.length;
  
        // Get today's date
        const today = new Date();
  
        // Calculate the last Sunday before today
        const lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
  
        // Convert the last Sunday to an ISO string
        const lastWeekEndDate = lastSunday.toISOString();
  
        const totalLeadsPreviousResponse = await api.get('/leads/total', {
          params: { user: currentUser, endDate: lastWeekEndDate },
        });
        const totalLeadsPreviousCount = totalLeadsPreviousResponse.data.total;
  
        const totalNewLeadsCount = totalLeadsCount - totalLeadsPreviousCount;
  
        // Calculate percentage change
        const percentageChange =
          totalLeadsPreviousCount === 0
            ? totalLeadsCount === 0
              ? 0
              : 100
            : ((totalLeadsCount - totalLeadsPreviousCount) / totalLeadsPreviousCount) * 100;
  
        setTotalLeadsCount(totalLeadsCount);
        setTotalNewLeadsCount(totalNewLeadsCount);
        setPreviousLeadsCount(totalLeadsPreviousCount);
        setPercentageChange(percentageChange.toFixed(2));
        setPercentageChangeClass(percentageChange >= 0 ? 'percentage-positive' : 'percentage-negative');
  
        // Fetch other data (logs and campaigns)
        const [logsResponse, campaignsResponse] = await Promise.all([
          api.get(`/logs/${currentUser}`), // Adjust endpoint for logs if needed
          api.get(`/organization/${organizationId}/campaigns`), // Fetch campaigns for the organization
        ]);

        if(campaignsResponse) {

        
          const onboardingComplete = campaignsResponse.data.some(
            campaign => campaign.status !== 'Concept' && campaign.user === currentUser
          );

          console.log("onboardingComplete: " + onboardingComplete);
          
          if(!onboardingComplete) {
           navigate('/onboarding');
          }

        }
  
        const newConnections = logsResponse.data.filter((log) => log.action === 'requestAccepted');
  
        // Count new connections this week
        const newConnectionsThisWeek = newConnections.filter((log) => new Date(log.timestamp) > new Date(lastWeekEndDate)).length;
  
        // Set new connections data
        setNewConnections(newConnections.length);
        setNewConnectionsThisWeek(newConnectionsThisWeek);
  
        const filteredCampaigns = campaignsResponse.data.filter(
          (campaign) => campaign.status === 'Active' || campaign.status === 'Concept'
        );
  
        const filteredLogs = logsResponse.data.filter((log) => log.action !== 'waitTime');
        setLogs(filteredLogs.slice(-10));
        setCampaigns(filteredCampaigns);

  
        setIsDataLoaded(true);
      } else {
        console.error('User not found or organization ID is missing.');
        // Handle the case when user is not found or organization ID is missing
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLogs([]);
      setCampaigns([]);
      setTotalLeadsCount(0);
      setPercentageChange(0);
      setIsDataLoaded(false); // Optionally set loading state to false
    }
  };
  



  const fetchActiveCampaigns = async () => {
    try {
        const userEmail = Userfront.user.email; // Get the email from Userfront

        // First, fetch the user details to get the organization ID
        const userResponse = await api.get(`/user?email=${userEmail}`);

        // Ensure user data is available
        if (userResponse.status === 200 && userResponse.data.user) {
            const organizationId = userResponse.data.user.organizationId; // Assuming organizationId is part of user data

            // Now fetch campaigns for the organization
            const campaignsResponse = await api.get(`/organization/${organizationId}/campaigns`);

            // Filter campaigns to include only "Active," "Concept," or "Paused" status
            const filteredCampaigns = campaignsResponse.data.filter(campaign =>
                campaign.status === 'Active' || campaign.status === 'Concept' || campaign.status === 'Paused'
            );

            // Sort campaigns by createdAt date in descending order
            const sortedCampaigns = filteredCampaigns.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            // Take the first 5 campaigns from the sorted array
            const lastFiveCampaigns = sortedCampaigns.slice(0, 5);

            // Set campaigns data as an array of the last 5 campaigns
            setActiveCampaigns(lastFiveCampaigns);

            // Count the number of active campaigns
            const activeCount = lastFiveCampaigns.filter(campaign => campaign.status === 'Active').length;
            setActiveCampaignsCount(activeCount);
        } else {
            console.error('User not found or organization ID is missing.');
            setActiveCampaigns([]);
            setActiveCampaignsCount(0);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        setActiveCampaigns([]);
        setActiveCampaignsCount(0);
    }
};

  

  const fetchUnreadMessagesCount = async () => {
    try {

      const userEmail = Userfront.user.email; // Get the email from Userfront

      const response = await api.get(`/messages/unread-count?email=${userEmail}`);
      setUnreadMessagesCount(response.data.unreadCount); // Set unread messages count
    } catch (error) {
      console.error('Error fetching unread messages count:', error);
      setUnreadMessagesCount(0);
    }
  };

  const collapsed = usePersistentCollapsedState();

  const generateTableData = () => {
    if (!logs.length || !isDataLoaded) {
      console.log('Logs data is empty or data not loaded yet');
      return [];
    }
  
    return logs
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) // Sort by timestamp in descending order
      .map((log, index) => ({
        id: index,
        activity: log.action,
        contact: log.leadName || 'Unknown',
        teamMember: log.userId,
        date: new Date(log.timestamp).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' }), // Format date and time together
        campaignTitle: log.campaignTitle || 'Unknown Campaign',
      }));
  };
  

  const getActivityIcon = (activity) => {
    switch (activity) {
      case 'viewProfile':
        return <FontAwesomeIcon icon={faEye} />;
      case 'sendConnectionRequest':
        return <FontAwesomeIcon icon={faPaperPlane} />;
      default:
        return <FontAwesomeIcon icon={faUser} />;
    }
  };

  // Update the getActivityDescription function for new keys
  const getActivityDescription = (activity) => {
    switch (activity) {
      case 'viewProfile':
        return t('home.viewedProfile'); // Updated for translation
      case 'sendConnectionRequest':
        return t('home.sentConnectionRequest'); // Updated for translation
      case 'messageReceived':
        return t('home.messageReceived'); // Updated for translation
      case 'messageSent':
        return t('home.messageSent'); // Updated for translation
      // Add more cases as needed
      default:
        return t('home.unknownActivity'); // Updated for translation
    }
  };

  const toggleTooltip = (id) => {
    setTooltips({ ...tooltips, [id]: !tooltips[id] });
  };

  const getStatusLabelClass = (status) => {
    switch (status) {
      case 'Concept':
        return 'status-label-concept';
      case 'Active':
        return 'status-label-active';
      case 'Paused':
        return 'status-label-paused';
      case 'Completed':
        return 'status-label-completed';
      case 'Stopped':
        return 'status-label-stopped';
      case 'Archived':
        return 'status-label-archived';
      default:
        return '';
    }
  };

  const renderTooltip = (user, props) => (
    <Tooltip id="button-tooltip" className='custom-tooltip' {...props}>
      {user}
    </Tooltip>
  );

  const calculateReplyRate = (leads) => {
    if (!Array.isArray(leads) || leads.length === 0) return 0;
    const repliedLeads = leads.filter(lead => lead.replied).length;
    
    var acceptedLeads = leads.filter(lead => lead.accepted).length;

   
    if(acceptedLeads){

    return ((repliedLeads / acceptedLeads) * 100).toFixed(2);

     } else {
      return ((repliedLeads / leads.length) * 100).toFixed(2);
     }
  };

  const calculateAcceptanceRate = (leads) => {
    if (!Array.isArray(leads) || leads.length === 0) return 0;
    const acceptedLeads = leads.filter(lead => lead.accepted).length;
    return ((acceptedLeads / leads.length) * 100).toFixed(2);
  };
  

  return (
    <div className={`dashboard ${collapsed ? 'collapsed' : ''}`}>
      <Sidebar />
      <div className="container">
        <NavBar />

     
      
        <div className="row statsCardRow page-row">
          <div className="col-md-3">
            <div className="stats-card stats-card-primary rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                <FontAwesomeIcon icon={faUsers} className="stats-icon" />
              </div>
              <h2 className='stats-card-value'>{totalLeadsCount}</h2>
              <p className='stats-span font-medium text-sm'>
                {t('home.totalLeads')} 
                <span className={`stats-span-percentage ${percentageChangeClass}`}> {totalNewLeadsCount} {t('home.thisWeek')}</span>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="stats-card stats-card-primary rounded-sm border bg-white py-6 px-7.5 shadow-default">
              <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2">
                <FontAwesomeIcon icon={faUserPlus} className="stats-icon" />
              </div>
              <h2 className='stats-card-value'>{newConnections}</h2>
              <p className='stats-span font-medium text-sm '>
                {t('home.newConnections')}
                <span className={`stats-span-percentage ${percentageChangeClass}`}>{newConnectionsThisWeek} {t('home.thisWeek')}</span>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="stats-card stats-card-primary rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                <FontAwesomeIcon icon={faEnvelope} className="stats-icon" />
              </div>
              <h2 className='stats-card-value'>{unreadMessagesCount}</h2>
              <p className='stats-span font-medium text-sm'>{t('home.unreadMessages')}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="stats-card stats-card-primary rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                <FontAwesomeIcon icon={faBullseye} className="stats-icon" />
              </div>
              <h2 className='stats-card-value'>{activeCampaignsCount}</h2>
              <p className='stats-span font-medium text-sm'>{t('home.activeCampaigns')}</p>
            </div>
          </div>
        </div>
  
        <div className="row page-row">
          <div className={`col-md-12 col-sm-12 timeline-graph`}>
            <div className="activities-widget activities-widget-home row-1">    
              <MixedChart />
            </div>
          </div>
      

          <div className="col-md-6">
            <div className="activities-widget widget-margin-bottom activities-widget-home row-1">
              <div className="header-container header-container-home">
                <h2>{t('home.recentActivities')}</h2>
              </div>
              <div className="timeline">
                <div className="timeline-content-wrapper">
                  {generateTableData().map((row, index) => (
                    <div className="timeline-event" key={index}>
                      <div className="timeline-icon">
                        {getActivityIcon(row.activity)}
                      </div>
                      <div className="timeline-content">
                        <div className="timeline-header">
                          <span className="timeline-activity">
                            {getActivityDescription(row.activity)} {t('home.forLead')} {row.contact} {t('home.in')} {row.campaignTitle}
                          </span>
                        </div>
                        <div className="timeline-date">
                          {row.date}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="activities-widget activities-widget-home row-1">
              <div className="header-container header-container-home">
                <h2>{t('home.dailyLimits')}</h2>
                <Link to="/settings?tab=saveLimits" className='btn btn-primary'>{t('home.manageLimits')}</Link>
              </div>
              <LimitsChart />
            </div>
          </div>

      
          <div className="col-md-12">
            <div className="activities-widget activities-widget-home campaigns-home">
              <div className="header-container header-container-home">
                <h2>{t('home.recentCampaigns')}</h2>
                <Link to="/campaigns" className='btn btn-primary'>{t('home.allCampaigns')}</Link>
              </div>
              <div className='table-responsive'>
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: '30%' }}>{t('home.campaign')}</th>
                    <th style={{ width: '20%' }}>{t('home.status')}</th>
                    <th style={{ width: '20%' }}>{t('home.acceptanceRate')}</th>
                    <th style={{ width: '20%' }}>{t('home.replyRate')}</th>
                    <th style={{ width: '10%' }}>{t('home.user')}</th>
                  </tr>
                </thead>
                <tbody>
                  {activeCampaigns.map((campaign, index) => (
                    <tr key={index}>
                      <td><Link to={'/campaigns/' + campaign._id}> {campaign.title} </Link> </td>
                      <td>
                        <div className={`status-label-container ${getStatusLabelClass(campaign.status)}`}>
                          {campaign.status}
                        </div>
                      </td>
                      <td>
                        <div className="rate-bar">
                          <div
                            className="rate-fill"
                            style={{ width: `${calculateAcceptanceRate(campaign.leads)}%` }}
                          />
                        </div>
                        {calculateAcceptanceRate(campaign.leads)}%
                      </td>
                      <td>
                        <div className="rate-bar">
                          <div
                            className="rate-fill"
                            style={{ width: `${calculateReplyRate(campaign.leads)}%` }}
                          />
                        </div>
                        {calculateReplyRate(campaign.leads)}%
                      </td>
                      <td>
                        <OverlayTrigger
                          placement="top" 
                          overlay={renderTooltip(campaign.user)}
                        >
                          <div className="user-initial">{currentUser?.charAt(0).toUpperCase()}</div>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              </div>
            </div>
          </div>
        </div>

     
      

      </div>
    </div>
  );
  
  

};

export default Home;
